import { combineReducers } from "redux";

import cepReducer from "./cep-reducer";
import imageReducer from "./image-reducer";
import loginReducer from "./login-reducer";
import filesReducer from "./files-reducer";
import pessoaReducer from "./pessoa-reducer";
import messageReducer from "./message-reducer";
import templateReducer from "./template-reducer";
import stripeReducer from "./stripe-reducer";

export default combineReducers({
  loginReducer,
  messageReducer,
  pessoaReducer,
  templateReducer,
  cepReducer,
  filesReducer,
  imageReducer,
  stripeReducer
});
