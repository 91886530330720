import { call, takeLatest, put, all } from "redux-saga/effects";

import message from "antd/lib/message";

import ImageServices from "~/Services/image-service";

import { imageActionCreators } from "~/Redux/image-reducer";

const {
  imageRequest,
  imageSuccess,
  imageError,
  clearImage,
} = imageActionCreators();

const { getAllImages, getImageName } = ImageServices;

const imageSagaTypes = {
  REQUEST: "IMAGE_SAGA_REQUEST",
  REQUEST_NAME: "IMAGE_NAME_SAGA_REQUEST",
  CLEAR: "IMAGE_SAGA_CLEAR",
};

function* searchAllImages() {
  yield put(imageRequest());
  try {
    const { data } = yield call(getAllImages);
    yield put(imageSuccess(data));
  } catch (err) {
    yield put(imageError());
    message.error("Imagem não encontrada");
  }
}

function* searchImageName(values) {
  yield put(imageRequest());
  try {
    const { data } = yield call(getImageName, values.payload);
    yield put(imageSuccess(data.results));
  } catch (err) {
    yield put(imageError());
    message.error("Imagem não encontrada");
  }
}

function* clearImageState() {
  try {
    yield put(clearImage());
  } catch (err) {
    message.error("Erro ao limpar a imagem", err);
  }
}

export function imageSagaActions() {
  return {
    imageAllSagaRequest: () => {
      return {
        type: imageSagaTypes.REQUEST,
      };
    },
    clearImageRequest: () => {
      return {
        type: imageSagaTypes.CLEAR,
      };
    },
    imageNameRequest: (payload) => {
      return {
        type: imageSagaTypes.REQUEST_NAME,
        payload,
      };
    },
  };
}

export default function* cepSaga() {
  yield all([
    takeLatest(imageSagaTypes.REQUEST, searchAllImages),
    takeLatest(imageSagaTypes.CLEAR, clearImageState),
    takeLatest(imageSagaTypes.REQUEST_NAME, searchImageName),
  ]);
}
