export const pessoaTypes = {
  REQUEST: "PESSOA_REQUEST",
  SUCCESS: "PESSOA_SUCCESS",
  ERROR: "PESSOA_ERROR",
};

const initialState = {
  loading: false,
  pessoa: {},
  err: {},
  code: null
};

export default function pessoaReducer(state = initialState, action) {
  switch (action.type) {
    case pessoaTypes.REQUEST:
      return {
        ...state,
        loading: true,
        pessoa: {},
        err: {}
      };
    case pessoaTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        pessoa: action.payload || {},
      };
    case pessoaTypes.ERROR:
      return {
        ...state,
        loading: false,
        err: action.payload,
      };

    default:
      return state;
  }
}

export const pessoaActionCreators = () => ({
  pessoaRequest: () => ({
    type: pessoaTypes.REQUEST,
  }),
  pessoaSuccess: (payload) => ({
    type: pessoaTypes.SUCCESS,
    payload,
  }),
  pessoaError: (payload) => ({
    type: pessoaTypes.ERROR,
    payload
  }),
});
