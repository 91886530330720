import { lazy } from "react";

const WebSiteUser = lazy(() => import("~/Pages/WebSiteUser"));

function createObj(obj) {
  const newObj = {
    key: obj._id,
    path: `/${obj.configuracoes.urlSite}`,
    exact: true,
    Component: WebSiteUser,
    mySiteId: obj._id,
    private: false,
  };

  return newObj;
}

export { createObj };
