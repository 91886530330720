export const cepTypes = {
  REQUEST: "CEP_REQUEST",
  SUCCESS: "CEP_SUCCESS",
  ERROR: "CEP_ERROR",
  CLEAR: "CEP_CLEAR",
};

const initialState = {
  loading: false,
  error: "",
  enderecoPessoa: {},
};

export default function cepReducer(state = initialState, action) {
  switch (action.type) {
    case cepTypes.REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case cepTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        enderecoPessoa: action.payload,
        error: "",
      };
    case cepTypes.ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        enderecoPessoa: {},
      };

    case cepTypes.CLEAR:
      return {
        ...state,
        loading: false,
        enderecoPessoa: {},
        error: "",
      };

    default:
      return state;
  }
}

export const cepActionCreators = () => ({
  cepRequest: () => ({
    type: cepTypes.REQUEST,
  }),
  cepSuccess: (payload) => ({
    type: cepTypes.SUCCESS,
    payload,
  }),
  cepError: () => ({
    type: cepTypes.ERROR,
  }),
  clearCep: () => ({
    type: cepTypes.CLEAR,
  }),
});
