import { call, takeLatest, put, all } from "redux-saga/effects";

import TemplateServices from "~/Services/template-services";

import { templateActionCreators } from "~/Redux/template-reducer";
import { messageActionCreators } from "~/Redux/message-reducer";

import { handleApiError } from "~/Utils/handleApiResponse";

const {
  templateGetRequest,
  templateGetSuccess,
  templatePostRequest,
  templatePostSuccess,
  allTemplateUrlRequest,
  allTemplateUrlSuccess,
  deleteTemplateRequest,
  deleteTemplateSuccess,
  clearTemplateSaga,
  validarNomeTemplateRequest,
  validarNomeTemplateSuccess,
  validarNomeTemplateError,
} = templateActionCreators();
const { displayMessage } = messageActionCreators();

const {
  fetchSalvarTemplate,
  fetchBuscarTemplates,
  fetchBuscarTodosTemplatesUrl,
  fetchDeleteTemplate,
  fetchValidaNomeTemplate,
  fetchSendMail
} = TemplateServices;

const templateSagaTypes = {
  POST: "TEMPLATE_SAGA_POST_REQUEST",
  GET: "TEMPLATE_SAGA_GET_REQUEST",
  ALL_TEMPLATES_URL: "TEMPLATE_SAGA_ALL_TEMPLATES_URL_REQUEST",
  DELETE_TEMPLATE_URL: "DELETE_TEMPLATE_URL",
  CLEAR_TEMPLATE_URL: "CLEAR_TEMPLATE_URL",
  VALIDAR_NOME_TEMPLATE: "VALIDAR_NOME_TEMPLATE",
  EMAIL_REQUEST: "EMAIL_REQUEST",
};

function* handleTemplatePostSaga(values) {
  yield put(templatePostRequest());

  try {
    yield call(fetchSalvarTemplate, values.payload, values.token);

    yield put(templatePostSuccess());

    if (values.callback) yield call(values.callback);
  } catch (err) {
    const error = yield call(handleApiError, err);

    yield put(displayMessage(error));
  }
}

function* handleTemplateGetSaga(values) {
  yield put(templateGetRequest());

  try {
    const { data } = yield call(fetchBuscarTemplates, values.payload);
    yield put(templateGetSuccess(data));
  } catch (err) {
    const error = yield call(handleApiError, err);

    yield put(displayMessage(error));
  }
}

function* handleAllTemplatesUrlSaga() {
  yield put(allTemplateUrlRequest());

  try {
    const { data } = yield call(fetchBuscarTodosTemplatesUrl);

    yield put(allTemplateUrlSuccess(data));
  } catch (err) {
    const error = yield call(handleApiError, err);

    yield put(displayMessage(error));
  }
}

function* handleDeleteTemplateSaga(values) {
  yield put(deleteTemplateRequest());

  try {
    yield call(fetchDeleteTemplate, values.payload);

    yield put(deleteTemplateSuccess());

    yield put(
      displayMessage({
        type: "success",
        text: "Seu site foi excluído com sucesso",
      })
    );

    const { data } = yield call(fetchBuscarTemplates);

    yield put(templateGetSuccess(data));
  } catch (err) {
    const error = yield call(handleApiError, err);

    yield put(displayMessage(error));
  }
}

function* handleClearTemplateSaga() {
  try {
    yield put(clearTemplateSaga());
  } catch (err) {
    displayMessage({
      type: "err",
      text: "Erro ao limpar o template",
    });
  }
}

function* handleValidarNomeTemplateSaga(values) {
  yield put(validarNomeTemplateRequest());

  try {
    const { data } = yield call(fetchValidaNomeTemplate, values.payload);
    yield put(validarNomeTemplateSuccess(data));
  } catch (err) {
    const error = yield call(handleApiError, err);
    yield put(validarNomeTemplateError({ success: false }));
    yield put(displayMessage(error));
  }
}

function* handleEmailRequest(values) {
  try {
    const { data } = yield call(fetchSendMail, values.payload);

    yield put(displayMessage({ type: "success", text: data.message }));
  } catch (err) {
    const error = yield call(handleApiError, err);
    yield put(displayMessage(error));
  }
}

export function templateSagaActions() {
  return {
    templateSagaPostRequest: (payload, token) => ({
      type: templateSagaTypes.POST,
      payload,
      token,
    }),
    templateSagaGetRequest: (payload) => ({
      type: templateSagaTypes.GET,
      payload,
    }),
    todosTemplatesUrlRequest: () => ({
      type: templateSagaTypes.ALL_TEMPLATES_URL,
    }),
    deleteTemplateRequest: (payload) => ({
      type: templateSagaTypes.DELETE_TEMPLATE_URL,
      payload,
    }),
    clearTemplateSagaRequest: () => ({
      type: templateSagaTypes.CLEAR_TEMPLATE_URL,
    }),
    validarNomeTemplateRequest: (payload) => ({
      type: templateSagaTypes.VALIDAR_NOME_TEMPLATE,
      payload,
    }),
    sendMailRequest: (payload) => ({
      type: templateSagaTypes.EMAIL_REQUEST,
      payload,
    }),
  };
}

export default function* templateSaga() {
  yield all([
    takeLatest(templateSagaTypes.POST, handleTemplatePostSaga),
    takeLatest(templateSagaTypes.GET, handleTemplateGetSaga),
    takeLatest(templateSagaTypes.ALL_TEMPLATES_URL, handleAllTemplatesUrlSaga),
    takeLatest(templateSagaTypes.DELETE_TEMPLATE_URL, handleDeleteTemplateSaga),
    takeLatest(templateSagaTypes.CLEAR_TEMPLATE_URL, handleClearTemplateSaga),
    takeLatest(
      templateSagaTypes.VALIDAR_NOME_TEMPLATE,
      handleValidarNomeTemplateSaga
    ),
    takeLatest(templateSagaTypes.EMAIL_REQUEST, handleEmailRequest),
  ]);
}
