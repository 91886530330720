import { call, takeLatest, put, all, putResolve } from "redux-saga/effects";

import message from "antd/lib/message";

import PessoaServices from "~/Services/pessoa-services";

import { loginActionCreators } from "~/Redux/login-reducer";
import { pessoaActionCreators } from "~/Redux/pessoa-reducer";
import { messageActionCreators } from "~/Redux/message-reducer";

import { setStorage } from "~/Utils/storage";
import { handleApiError, handleApiSuccess } from "~/Utils/handleApiResponse";

const { pessoaRequest, pessoaSuccess, pessoaError } = pessoaActionCreators();
const { loginSuccess } = loginActionCreators();
const { displayMessage } = messageActionCreators();

const {
  fetchCadastro,
  fetchBuscarPessoa,
  fetchValidarEmail,
  fetchConfirmarConta,
  fetchAtualizarSenha,
  fetchBuscarRecuperacao,
  fetchSuport
} = PessoaServices;

const pessoaSagaTypes = {
  POST: "PESSOA_SAGA_POST_REQUEST",
  GET: "PESSOA_SAGA_GET_REQUEST",
  VALIDATE: "PESSOA_SAGA_VALIDATE_REQUEST",
  VALIDATE_ACCOUNT: "PESSOA_SAGA_VALIDATE_ACCOUNT_REQUEST",
  GET_ACCOUNT_RECOVERY: "PESSOA_SAGA_GET_ACCOUNT_RECOVERY_REQUEST",
  UPDATE_PASSWORD: "PESSOA_SAGA_UPDATE_PASSWORD_REQUEST",
  SUPORT: "SUPORT",
};

function* handlePessoaPostSaga(values) {
  yield put(pessoaRequest());

  try {
    const { data } = yield call(fetchCadastro, values.payload);

    if (data.token) {
      yield call(setStorage, "token", data.token);
      yield put(loginSuccess(data.token));
    }

    if (data.pessoa) {
      yield put(pessoaSuccess(data.pessoa));

      const successResponse = yield call(handleApiSuccess, data);
      yield put(displayMessage(successResponse));
    } else yield put(pessoaSuccess());

    if (values.callback) yield call(values.callback);
  } catch (err) {
    const error = yield call(handleApiError, err);

    yield put(displayMessage(error));
  }
}

function* handlePessoaGetSaga() {
  yield put(pessoaRequest());

  try {
    const { data } = yield call(fetchBuscarPessoa);

    yield put(pessoaSuccess(data.pessoa));
  } catch (err) {
    const error = yield call(handleApiError, err);

    yield putResolve(displayMessage(error));
  }
}

function* handleValidarEmailSaga(values) {
  yield put(pessoaRequest());

  try {
    const { data } = yield call(fetchValidarEmail, values.payload);

    yield put(pessoaSuccess(data));
  } catch (err) {
    yield put(pessoaError(err.response.data));
  }
}

function* handleConfirmacaoConta(values) {
  yield put(pessoaRequest());

  try {
    const { data } = yield call(fetchConfirmarConta, values.payload);

    yield put(pessoaSuccess(data));
  } catch (err) {
    yield put(pessoaError(err.response.data));
  }
}

function* handleBuscarRecuperacao(values) {
  yield put(pessoaRequest());

  try {
    const { data } = yield call(fetchBuscarRecuperacao, values.payload);

    yield put(pessoaSuccess(data.pessoa));
  } catch (err) {
    if (err.response) {
      message.error(err.response.data.message, 1.5);

      if (values.callback) values.callback();
    }

    yield put(pessoaError(err.response.data));
  }
}

function* handleAtualizarSenha(values) {
  yield put(pessoaRequest());

  try {
    const { data } = yield call(fetchAtualizarSenha, values.payload);

    if (values.callback) values.callback();

    yield put(pessoaSuccess(data.pessoa));
  } catch (err) {
    if (err.response) {
      message.error(err.response.data.message, 1.5);
    }

    yield put(pessoaError(err.response.data));
  }
}

function* handleSuport(values) {

  try {
    const { data } = yield call(fetchSuport, values.payload);

    yield put(displayMessage({ type: "success", text: data.message }));

  } catch (err) {
    if (err.response) {
      message.error(err.response.data.message, 1.5);
    }

  }
}

export function pessoaSagaActions() {
  return {
    pesssoaSagaPostRequest: (payload, callback) => ({
      type: pessoaSagaTypes.POST,
      payload,
      callback,
    }),
    pessoaSagaGetRequest: () => ({
      type: pessoaSagaTypes.GET,
    }),
    pessoaSagaValidateRequest: (payload) => ({
      type: pessoaSagaTypes.VALIDATE,
      payload,
    }),
    pessoaSagaValidateAccountRequest: (payload) => ({
      type: pessoaSagaTypes.VALIDATE_ACCOUNT,
      payload,
    }),
    pessoaSagaGetAccountRecoveryRequest: (payload, callback) => ({
      type: pessoaSagaTypes.GET_ACCOUNT_RECOVERY,
      payload,
      callback,
    }),
    pessoaSagaUpdatePasswordRequest: (payload) => ({
      type: pessoaSagaTypes.UPDATE_PASSWORD,
      payload,
    }),
    suportRequest: (payload) => ({
      type: pessoaSagaTypes.SUPORT,
      payload,
    }),
  };
}

export default function* cadastroSagas() {
  yield all([
    takeLatest(pessoaSagaTypes.GET, handlePessoaGetSaga),
    takeLatest(pessoaSagaTypes.POST, handlePessoaPostSaga),
    takeLatest(pessoaSagaTypes.VALIDATE, handleValidarEmailSaga),
    takeLatest(pessoaSagaTypes.UPDATE_PASSWORD, handleAtualizarSenha),
    takeLatest(pessoaSagaTypes.VALIDATE_ACCOUNT, handleConfirmacaoConta),
    takeLatest(pessoaSagaTypes.GET_ACCOUNT_RECOVERY, handleBuscarRecuperacao),
    takeLatest(pessoaSagaTypes.SUPORT, handleSuport),
  ]);
}
