import React, { memo } from "react";
import LoadingWrapper from "./style";

const Loading = memo(() => (
  <LoadingWrapper className="loading-content">
    <div className="loading">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  </LoadingWrapper>
));

export default Loading;
