export const imageTypes = {
  REQUEST: "IMAGE_REQUEST",
  SUCCESS: "IMAGE_SUCCESS",
  ERROR: "IMAGE_ERROR",
  CLEAR: "IMAGE_CLEAR",
};

const initialState = {
  loading: false,
  error: "",
  images: [],
};

export default function imageReducer(state = initialState, action) {
  switch (action.type) {
    case imageTypes.REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case imageTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        images: action.payload,
        error: "",
      };
    case imageTypes.ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        images: {},
      };

    case imageTypes.CLEAR:
      return {
        ...state,
        loading: false,
        images: {},
        error: "",
      };

    default:
      return state;
  }
}

export const imageActionCreators = () => ({
  imageRequest: () => ({
    type: imageTypes.REQUEST,
  }),
  imageSuccess: (payload) => ({
    type: imageTypes.SUCCESS,
    payload,
  }),
  imageError: () => ({
    type: imageTypes.ERROR,
  }),
  clearImage: () => ({
    type: imageTypes.CLEAR,
  }),
});
