import axios from "~/Utils/axios";
import { getStorage } from "~/Utils/storage";

class TemplateService {
  fetchSalvarTemplate(data, token) {
    if (data._id) {
      return axios.put(`/templates/${data._id}`, data, {
        headers: {
          "x-access-token": token || getStorage("token"),
        },
      });
    }

    return axios.post("/templates/salvar", data, {
      headers: {
        "x-access-token": getStorage("token"),
      },
    });
  }

  fetchBuscarTemplates(id) {
    if (id) {
      return axios.get(`/templates/${id}`);
    }

    return axios.get("/templates", {
      headers: {
        "x-access-token": getStorage("token"),
      },
    });
  }

  fetchBuscarTodosTemplatesUrl() {
    return axios.get("/templates/url-sites", {
      headers: {
        "x-access-token": getStorage("token"),
      },
    });
  }

  fetchDeleteTemplate(id) {
    return axios.delete(`/templates/${id}`, {
      headers: {
        "x-access-token": getStorage("token"),
      },
    });
  }

  fetchValidaNomeTemplate(name) {
    return axios.get(`/templates/validar-nome?name=${name}`);
  }
  fetchSendMail(data) {
    return axios.post(`/templates/enviar-email`, data);
  }
}

export default new TemplateService();
