import { call, takeLatest, put } from "redux-saga/effects";

import LoginServices from "~/Services/login-services";

import { loginActionCreators } from "~/Redux/login-reducer";
import { messageActionCreators } from "~/Redux/message-reducer";

import { setStorage } from "~/Utils/storage";
import { handleApiError } from "~/Utils/handleApiResponse";

const { loginRequest, loginSuccess, loginError } = loginActionCreators();
const { displayMessage } = messageActionCreators();

const { fetchLogin, resetPassword } = LoginServices;

const loginSagaTypes = {
  REQUEST: "LOGIN_SAGA_REQUEST",
  REQUEST_RESET_PASSWORD: "REQUEST_RESET_PASSWORD",
};

function* handeLoginSaga(values) {
  yield put(loginRequest());

  try {
    const { data } = yield call(fetchLogin, values.payload);

    yield call(setStorage, "token", data.token);

    yield put(loginSuccess(data.token));

    if (values.callback) yield call(values.callback);
  } catch (err) {
    const error = yield call(handleApiError, err);
    yield put(loginError());
    yield put(displayMessage(error));
  }
}

function* resetPasswordSaga(values) {
  try {
    const { data } = yield call(resetPassword, values.payload);
    
    yield put(displayMessage({ type: "success", text: data.message }));
  } catch (err) {
    const error = yield call(handleApiError, err);
    yield put(loginError());
    yield put(displayMessage(error));
  }
}

export function loginSagaActions() {
  return {
    loginSagaRequest: (payload, callback) => ({
      type: loginSagaTypes.REQUEST,
      payload,
      callback,
    }),
    resetPasswordRequest: (payload) => ({
      type: loginSagaTypes.REQUEST_RESET_PASSWORD,
      payload,
    }),
  };
}

export default function* loginSaga() {
  yield takeLatest(loginSagaTypes.REQUEST, handeLoginSaga);
  yield takeLatest(loginSagaTypes.REQUEST_RESET_PASSWORD, resetPasswordSaga);
}
