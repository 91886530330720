import { lazy } from "react";

const Home = lazy(() => import("~/Pages/Home"));
const Gerador = lazy(() => import("~/Pages/Gerador"));
const LoginAdm = lazy(() => import("~/Pages/LoginAdm"));
const MeuPainel = lazy(() => import("~/Pages/MeuPainel"));
const Pagamento = lazy(() => import("~/Pages/Pagamento"));
const SejaVendedor = lazy(() => import("~/Pages/SejaVendedor"));
const NossaEmpresa = lazy(() => import("~/Pages/NossaEmpresa"));
const PreviewWebSite = lazy(() => import("~/Pages/PreviewWebSite"));
const RecuperarSenha = lazy(() => import("~/Pages/RecuperarSenha"));
const ConfirmarConta = lazy(() => import("~/Pages/ConfirmarConta"));
const ConhecendoWeBuilder = lazy(() => import("~/Pages/ConhecendoWeBuilder"));

const routes = [
  {
    key: 1,
    path: "/",
    exact: true,
    Component: Home,
  },
  {
    key: 2,
    path: "/admin",
    exact: true,
    Component: LoginAdm,
  },
  {
    key: 3,
    path: "/gerador",
    exact: true,
    Component: Gerador,
  },
  {
    key: 4,
    path: "/seja-um-vendedor",
    exact: true,
    Component: SejaVendedor,
  },
  {
    key: 5,
    path: "/nossa-empresa",
    exact: true,
    Component: NossaEmpresa,
  },
  {
    key: 6,
    path: "/meu-painel",
    exact: true,
    Component: MeuPainel,
    private: true,
  },
  {
    key: 7,
    path: "/preview-web-site",
    exact: true,
    Component: PreviewWebSite,
  },
  {
    key: 8,
    path: "/pagamento",
    exact: true,
    Component: Pagamento,
  },
  {
    key: 9,
    path: "/conhecendo-landinggo",
    exact: true,
    Component: ConhecendoWeBuilder,
  },
  {
    key: 10,
    path: "/recuperar-senha",
    exact: true,
    Component: RecuperarSenha,
  },
  {
    key: 11,
    path: "/confirmar-conta",
    exact: true,
    Component: ConfirmarConta,
  },
];

export default routes;
