import { take, takeLatest, all, put } from "redux-saga/effects";

import { pessoaTypes } from "~/Redux/pessoa-reducer";
import { templateTypes } from "~/Redux/template-reducer";

import { pessoaSagaActions } from "../pessoa-sagas";
import { templateSagaActions } from "../template-sagas";

const { pessoaSagaGetRequest } = pessoaSagaActions();
const { templateSagaGetRequest } = templateSagaActions();

const meuPainelSagaFlowType = {
  START: "START_MEU_PAINEL_FLOW",
};

function* handleMeuPainelSagaFlow() {
  try {
    yield put(pessoaSagaGetRequest());

    yield take(pessoaTypes.SUCCESS);
    yield put(templateSagaGetRequest());

    yield take(templateTypes.GET_SUCCESS);
  } catch (err) {
    console.log(err);
  }
}

export function meuPainelSagaFlowActionCreators() {
  return {
    meuPainelSagaStarFlow: () => ({
      type: meuPainelSagaFlowType.START,
    }),
  };
}

export default function* meuPainelSagaFlow() {
  yield all([takeLatest(meuPainelSagaFlowType.START, handleMeuPainelSagaFlow)]);
}
