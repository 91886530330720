export const filesTypes = {
  REQUEST: "FILES_REQUEST",
  SUCCESS: "FILES_SUCCESS",
  DELETE: "FILES_DELETE",
};

const initialState = {
  loading: false,
  deletingCurrentFile: {},
};

export default function filesReducer(state = initialState, action) {
  switch (action.type) {
    case filesTypes.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case filesTypes.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case filesTypes.DELETE:
      return {
        ...state,
        deletingCurrentFile: action.payload
      };

    default:
      return state;
  }
}

export const filesActionCreators = () => ({
  filesRequest: () => ({
    type: filesTypes.REQUEST,
  }),
  filesSuccess: () => ({
    type: filesTypes.SUCCESS,
  }),
  deleteFile: (payload) => ({
    type: filesTypes.DELETE,
    payload
  }),
});
