import axios from "axios";

const baseUrl = (cep) => `https://viacep.com.br/ws/${cep}/json/`;

class CepServices {
  getCep(cep) {
    return axios.get(baseUrl(cep));
  }
}

export default new CepServices();
