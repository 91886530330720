import styled from "styled-components";

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;

  .loading {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
    animation: rotateLoad 3s linear infinite;

    .dot {
      width: 25px;
      height: 25px;
    }

    .dot:nth-child(1) {
      background-color: #2a9d8f;
      animation: animate 2s alternate infinite;
    }

    .dot:nth-child(2) {
      background-color: #e9c46a;
      animation: animate 3s alternate infinite;
    }

    .dot:nth-child(3) {
      background-color: #f4a261;
      animation: animate 4s alternate infinite;
    }

    .dot:nth-child(4) {
      background-color: #e76f51;
      animation: animate 5s alternate infinite;
    }

    @keyframes animate {
      0%,
      100% {
        transform: scale(1);
        border-radius: 50%;
      }

      50% {
        transform: scale(0);
        border-radius: 50%;
      }
    }
    @keyframes rotateLoad {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export default LoadingWrapper;
