import axios from "~/Utils/axios";
import { getStorage } from "~/Utils/storage";

class StripeServices {
  getConfig(token) {
    return axios.post(
      "/pagamento/config-sripe",
      {},
      {
        headers: {
          "x-access-token": token || getStorage("token"),
        },
      }
    );
  }

  getPaymentIntent(token) {
    return axios.post(
      "/pagamento/create-payment-intent",
      {},
      {
        headers: {
          "x-access-token": token || getStorage("token"),
        },
      }
    );
  }
}

export default new StripeServices();
