import axios from "~/Utils/axios";

class LoginServices {
  fetchLogin(data) {
    return axios.post("/login/auth", data);
  }
  resetPassword(data) {
    return axios.post("/pessoas/recuperar-senha", data)
  }
}

export default new LoginServices();
