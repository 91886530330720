import React, {
  createContext,
  memo,
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";

import message from "antd/lib/message";

import { messageActionCreators } from "./Redux/message-reducer";

import { setStorage, getStorage } from "./Utils/storage";

const { hideMessage } = messageActionCreators();

const ContextGlobal = createContext();

const initialState = {
  logado: getStorage("logado") || false,
};

const ContextGlobalProvider = memo(({ children }) => {
  const dispatch = useDispatch();
  const { messageReducer } = useSelector((state) => state);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (messageReducer.type !== "") {
      const { type, text } = messageReducer;

      message.open({
        type,
        content: text,
        onClose: () => dispatch(hideMessage()),
        duration: 1.5,
      });
    }
  }, [messageReducer, dispatch]);

  const logarSistema = useCallback((values) => {
    if (values.email === "1" && values.senha === "1") {
      setState((prevState) => ({
        ...prevState,
        logado: true,
      }));
      setStorage("logado", true);

      return true;
    }
    message.error("Usuário ou senha incorretos");
    return false;
  }, []);

  const providerValues = useMemo(() => {
    const { logado } = state;

    return {
      state: { logado },
      actions: {
        logarSistema,
      },
    };
  }, [state, logarSistema]);
  return (
    <ContextGlobal.Provider value={providerValues}>
      {children}
    </ContextGlobal.Provider>
  );
});

const useContextGlobal = () => useContext(ContextGlobal);

export { useContextGlobal };
export default ContextGlobalProvider;
