export const messageTypes = {
  DISPLAY: "DISPLAY_MESSAGE",
  HIDE: "HIDE_MESSAGE",
};

const initialState = {
  showMessage: false,
  type: "",
  text: "",
  statusCode: 0,
};

export default function messageReducer(state = initialState, action) {
  switch (action.type) {
    case messageTypes.DISPLAY:
      return {
        ...state,
        showMessage: true,
        type: action.payload.type,
        text: action.payload.text,
        statusCode: action.payload.statusCode,
      };

    case messageTypes.HIDE:
      return {
        ...state,
        showMessage: false,
        type: "",
        text: "",
        statusCode: 0,
      };

    default:
      return state;
  }
}

export const messageActionCreators = () => ({
  displayMessage: (payload) => ({
    type: messageTypes.DISPLAY,
    payload,
  }),
  hideMessage: () => ({
    type: messageTypes.HIDE,
  }),
});
