function setStorage(item, value) {
  return localStorage.setItem(item, JSON.stringify(value));
}

function getStorage(item) {
  const itemStorage = localStorage.getItem(item);

  return JSON.parse(itemStorage);
}

function removeStorage(item) {
  localStorage.removeItem(item);
}

export { setStorage, getStorage, removeStorage };
