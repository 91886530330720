function handleActionPayload(dataSource) {
  if (!dataSource) return {};

  if (dataSource.templates) {
    const pessoa = dataSource.templates.idPessoa;
    const templates = dataSource.templates.idTemplate;

    return {
      pessoa,
      templates,
    };
  }

  if (dataSource.template) {
    const template = dataSource.template;

    return {
      template,
    };
  }

  return {};
}

export const templateTypes = {
  POST_REQUEST: "TEMPLATE_POST_REQUEST",
  POST_SUCCESS: "TEMPLATE_POST_SUCCESS",

  GET_REQUEST: "TEMPLATE_GET_REQUEST",
  GET_SUCCESS: "TEMPLATE_GET_SUCCESS",

  GET_ALL_TEMPLATES_URL_REQUEST: "GET_ALL_TEMPLATES_URL_REQUEST",
  GET_ALL_TEMPLATES_URL_SUCCESS: "GET_ALL_TEMPLATES_URL_SUCCESS",

  DELETE_TEMPLATE_REQUEST: "DELETE_TEMPLATE_REQUEST",
  DELETE_TEMPLATE_SUCCESS: "DELETE_TEMPLATE_SUCCESS",

  CLEAR_TEMPLATE_SUCCESS: "CLEAR_TEMPLATE_SUCCESS",

  VALIDAR_NOME_TEMPLATE_REQUEST: "VALIDAR_NOME_TEMPLATE_REQUEST",
  VALIDAR_NOME_TEMPLATE_SUCCESS: "VALIDAR_NOME_TEMPLATE_SUCCESS",
  VALIDAR_NOME_TEMPLATE_ERROR: "VALIDAR_NOME_TEMPLATE_ERROR",
};

const initialState = {
  loading: false,
  pessoa: {},
  templates: [],
  template: {},
  todosTemplatesUrl: [],
  validaNomeTemplate: {},
  loadingValidaNomeTemplate: false,
};

export default function templateReducer(state = initialState, action) {
  switch (action.type) {
    case templateTypes.POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case templateTypes.POST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case templateTypes.GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case templateTypes.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        ...handleActionPayload(action.payload),
      };
    case templateTypes.GET_ALL_TEMPLATES_URL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case templateTypes.GET_ALL_TEMPLATES_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        todosTemplatesUrl: action.payload.templates,
      };
    case templateTypes.DELETE_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case templateTypes.DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case templateTypes.CLEAR_TEMPLATE_SUCCESS:
      return {
        ...state,
        template: {},
      };

    case templateTypes.VALIDAR_NOME_TEMPLATE_REQUEST:
      return {
        ...state,
        loadingValidaNomeTemplate: true,
      };

    case templateTypes.VALIDAR_NOME_TEMPLATE_SUCCESS:
      return {
        ...state,
        loadingValidaNomeTemplate: false,
        validaNomeTemplate: action.payload
      };

    case templateTypes.VALIDAR_NOME_TEMPLATE_ERROR:
      return {
        ...state,
        loadingValidaNomeTemplate: false,
        validaNomeTemplate: action.payload
      };

    default:
      return state;
  }
}

export const templateActionCreators = () => ({
  templatePostRequest: () => ({
    type: templateTypes.POST_REQUEST,
  }),
  templatePostSuccess: () => ({
    type: templateTypes.POST_SUCCESS,
  }),
  templateGetRequest: () => ({
    type: templateTypes.GET_REQUEST,
  }),
  templateGetSuccess: (payload) => ({
    type: templateTypes.GET_SUCCESS,
    payload,
  }),
  allTemplateUrlRequest: () => ({
    type: templateTypes.GET_ALL_TEMPLATES_URL_REQUEST,
  }),
  allTemplateUrlSuccess: (payload) => ({
    type: templateTypes.GET_ALL_TEMPLATES_URL_SUCCESS,
    payload,
  }),

  deleteTemplateRequest: () => ({
    type: templateTypes.DELETE_TEMPLATE_REQUEST,
  }),
  deleteTemplateSuccess: () => ({
    type: templateTypes.DELETE_TEMPLATE_SUCCESS,
  }),

  clearTemplateSaga: () => ({
    type: templateTypes.CLEAR_TEMPLATE_SUCCESS,
  }),

  validarNomeTemplateRequest: () => ({
    type: templateTypes.VALIDAR_NOME_TEMPLATE_REQUEST,
  }),
  validarNomeTemplateSuccess: (payload) => ({
    type: templateTypes.VALIDAR_NOME_TEMPLATE_SUCCESS,
    payload
  }),
  validarNomeTemplateError: (payload) => ({
    type: templateTypes.VALIDAR_NOME_TEMPLATE_ERROR,
    payload
  }),
});
