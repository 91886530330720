import axios from "axios";

const baseUrl = "https://api.unsplash.com/";

const id = "KZrqF-Iwwtm78dQN_kM8IvJ0LFGL58Yf-wJTX_TY_ic";

class ImageServices {
  getAllImages() {
    return axios.get(`${baseUrl}/photos/?client_id=${id}`);
  }
  getImageName(query) {
    return axios.get(
      `${baseUrl}/search/photos/?client_id=${id}&query=${query}`
    );
  }
}

export default new ImageServices();
