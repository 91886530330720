import { call, takeLatest, put, all } from "redux-saga/effects";

import FilesServices from "~/Services/files-services";

import { filesActionCreators } from "~/Redux/files-reducer";
import { messageActionCreators } from "~/Redux/message-reducer";

import { handleApiError } from "~/Utils/handleApiResponse";

const { filesRequest, filesSuccess, deleteFile } = filesActionCreators();
const { displayMessage } = messageActionCreators();

const { fetchRemoveFile } = FilesServices;

const filesSagaTypes = {
  REQUEST: "FILES_SAGA_REQUEST",

  DELETING: "FILES_SAGA_DELETING",
};

function* handeFilesSaga(values) {
  yield put(filesRequest());

  try {
    yield call(fetchRemoveFile, values.payload.id);

    yield put(filesSuccess());
  } catch (err) {
    const error = yield call(handleApiError, err);

    yield put(displayMessage(error));
  }
}

function* handleDeleteFilesSaga(values) {
  try {
    yield put(deleteFile(values.payload));
  } catch (err) {
    yield put(displayMessage(err));
  }
}

export function filesSagaActions() {
  return {
    filesSagaRequest: (payload) => ({
      type: filesSagaTypes.REQUEST,
      payload,
    }),
    deletingFilesRequest: (payload) => ({
      type: filesSagaTypes.DELETING,
      payload,
    }),
  };
}

export default function* filesSagas() {
  yield all([
    takeLatest(filesSagaTypes.REQUEST, handeFilesSaga),
    takeLatest(filesSagaTypes.DELETING, handleDeleteFilesSaga)
  ]);
}
