import axios from "axios";

const baseURL = "https://landinggo-api-production.up.railway.app";
// const baseURL = "http://localhost:8000";

export default axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "private, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: 0,
    "Access-Control-Allow-Origin": "*",
  },
});
