import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";

import "aos/dist/aos.css";
import "antd/dist/antd.css";
import "moment/locale/pt-br";

import BR from "antd/lib/locale-provider/pt_BR";
import ConfigProvider from "antd/lib/config-provider";

import AOS from "aos";

import getStore from './Store';

import ConfigureRoute from "./Routes";
import GlobalStyle from "./GlobalStyle";
import ContextGlobalProvider from "./GlobalContext";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

AOS.init();

ReactDOM.render(
  <Provider store={getStore()}>
    <ContextGlobalProvider>
      <GlobalStyle>
        <ConfigProvider locale={BR}>
          <ConfigureRoute />
        </ConfigProvider>
      </GlobalStyle>
    </ContextGlobalProvider>
  </Provider>,
  document.getElementById("root")
);
