import React, {
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { BrowserRouter as Router, Route } from "react-router-dom";

import Loading from "~/Components/Loading";

import { templateSagaActions } from "../Sagas/template-sagas";

import AuthRoute from "./AuthRoute";
import routes from "./routes";
import { createObj } from "./utils";

const { todosTemplatesUrlRequest } = templateSagaActions();

function RenderRoutes(route) {
  const { Component, path, exact } = route;

  const renderComponent = (props) => (
    <React.Suspense fallback={<Loading />}>
      {route.private ? (
        <AuthRoute>
          <Component {...props} routes={route.routes} />
        </AuthRoute>
      ) : (
        <Component
          {...props}
          idSite={route.mySiteId || {}}
          routes={route.routes}
        />
      )}
    </React.Suspense>
  );

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => renderComponent(props)}
    />
  );
}

const ConfigureRoute = () => {
  const [rotas, setRotas] = useState(null);
  const dispatch = useDispatch();
  const { todosTemplatesUrl } = useSelector((state) => state.templateReducer);

  useLayoutEffect(() => {
    dispatch(todosTemplatesUrlRequest());
  }, [dispatch]);

  const getNewRoutes = useCallback((templates) => {
    let newArr = [];
    if (templates.length > 0) {
      for (let i = 0; i < templates.length; i += 1) {
        const element = createObj(templates[i]);
        newArr = [...newArr, element];
      }
    }
    return newArr;
  }, []);

  useLayoutEffect(() => {
    const allRoutes = getNewRoutes(todosTemplatesUrl);
    const newRoutes = [...routes, ...allRoutes];
    setRotas(newRoutes);
  }, [todosTemplatesUrl, getNewRoutes]);

  return (
    <Router>
      {rotas &&
        rotas.length > 0 &&
        rotas.map((route) => <RenderRoutes key={route.key} {...route} />)}
    </Router>
  );
};

export { RenderRoutes };
export default ConfigureRoute;
