export function handleApiError(error) {
  const { response } = error;

  if (response.data) {
    const { message, statusCode } = response.data;

    return { type: "error", text: message, statusCode };
  }
}

export function handleApiSuccess(data) {
  if (data.message) {
    const { message, statusCode } = data;

    return { type: "success", text: message, statusCode };
  }
}
