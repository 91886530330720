import { call, takeLatest, put, all } from "redux-saga/effects";

import message from "antd/lib/message";

import CepServices from "~/Services/cep-services";

import { cepActionCreators } from "~/Redux/cep-reducer";

const { cepRequest, cepSuccess, cepError, clearCep } = cepActionCreators();

const { getCep } = CepServices;

const cepSagaTypes = {
  REQUEST: "CEP_SAGA_REQUEST",
  CLEAR: "CEP_SAGA_CLEAR",
};

function* searchCep(values) {
  yield put(cepRequest());
  try {
    const { data } = yield call(getCep, values.payload);
    if (data.erro) {
      yield put(cepError());
      message.error("CEP inválido");
    } else {
      yield put(cepSuccess(data));
    }
  } catch (err) {
    yield put(cepError());
    message.error("CEP não encontrado");
  }
}

function* cepClear() {
  try {
    yield put(clearCep());
  } catch (err) {
    message.error("Erro ao limpar o cep", err);
  }
}

export function cepSagaActions() {
  return {
    cepSagaRequest: (payload) => {
      return {
        type: cepSagaTypes.REQUEST,
        payload,
      };
    },
    cepClearRequest: () => {
      return {
        type: cepSagaTypes.CLEAR,
      };
    },
  };
}

export default function* cepSaga() {
  yield all([
    takeLatest(cepSagaTypes.REQUEST, searchCep),
    takeLatest(cepSagaTypes.CLEAR, cepClear),
  ]);
}
