import axios from "~/Utils/axios";
import { getStorage } from "~/Utils/storage";

class PessoaServices {
  fetchCadastro(data) {
    const token = getStorage("token");

    if (token) {
      return axios.put("/pessoas", data, {
        headers: {
          "x-access-token": token,
        },
      });
    }

    return axios.post("/pessoas/cadastro", data);
  }

  fetchBuscarPessoa() {
    return axios.get("/pessoas", {
      headers: {
        "x-access-token": getStorage("token"),
      },
    });
  }

  fetchValidarEmail(email) {
    return axios.get(`/pessoas/validar-email?email=${email}`);
  }

  fetchConfirmarConta(data) {
    return axios.put("/pessoas/confirmar-conta", data);
  }

  fetchBuscarRecuperacao(hash) {
    return axios.get(`/pessoas/buscar-recuperacao/${hash}`);
  }

  fetchAtualizarSenha(data) {
    return axios.put("/pessoas/atualizar-senha", data)
  }
  fetchSuport(data) {
    return axios.post("/pessoas/suporte", data)
  }
}

export default new PessoaServices();
