import React, { memo, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import { getStorage, setStorage } from "~/Utils/storage";

const AuthRoute = memo(({ history, children }) => {
  const { statusCode } = useSelector((state) => state.messageReducer);

  const [authToken, setAuthToken] = useState("");

  useEffect(() => {
    if (statusCode === 401) {
      setStorage("token", null);

      history.push("/admin");
    }
  }, [statusCode, history]);

  useEffect(() => {
    const token = getStorage("token");

    if (!token) history.push("/");
    else setAuthToken(token);
  }, [history]);

  return (
    <React.Fragment>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { token: authToken })
      )}
    </React.Fragment>
  );
});

export default withRouter(AuthRoute);
