import { call, takeLatest, put, all } from "redux-saga/effects";

import message from "antd/lib/message";

import stripeServices from "~/Services/stripe.services";

import { handleApiError } from "~/Utils/handleApiResponse";
import { messageActionCreators } from "~/Redux/message-reducer";
import { stripeActionCreators } from "~/Redux/stripe-reducer";

const {
  getConfigRequest,
  getConfigSuccess,
  getIntentionPaymentRequest,
  getIntentionPaymentSuccess,
} = stripeActionCreators();

const { getConfig, getPaymentIntent } = stripeServices;

const { displayMessage } = messageActionCreators();

const stripeSagaTypes = {
  GET_CONFIG_STRIPE: "GET_CONFIG_STRIPE",
  CREATE_PAYMENT_INTENTION: "CREATE_PAYMENT_INTENTION",
};

function* getConfigSaga(payload) {
  yield put(getConfigRequest());
  try {
    const { data } = yield call(getConfig);
    payload.callback(data);
    yield put(getConfigSuccess(data));
  } catch (err) {
    const error = yield call(handleApiError, err);

    yield put(displayMessage(error));
  }
}

function* createTransactionSaga(payload) {
  yield put(getIntentionPaymentRequest());

  try {
    const { data } = yield call(getPaymentIntent);
    payload.callback(data);
    yield put(getIntentionPaymentSuccess(data));
  } catch (err) {
    const error = yield call(handleApiError, err);

    yield put(displayMessage(error));
  }
}

export function stripeSagaActions() {
  return {
    actionConfigStripe: (callback) => ({
      type: stripeSagaTypes.GET_CONFIG_STRIPE,
      callback,
    }),
    actionCreateIntentionPaymentStripe: (callback) => ({
      type: stripeSagaTypes.CREATE_PAYMENT_INTENTION,
      callback,
    }),
  };
}

export default function* stripeSaga() {
  yield all([
    takeLatest(stripeSagaTypes.GET_CONFIG_STRIPE, getConfigSaga),
    takeLatest(stripeSagaTypes.CREATE_PAYMENT_INTENTION, createTransactionSaga),
  ]);
}
