const types = {
  GET_CONFIG_STRIPE_REQUEST: "GET_CONFIG_STRIPE_REQUEST",
  GET_CONFIG_STRIPE_SUCCESS: "GET_CONFIG_STRIPE_SUCCESS",

  GET_INTENTION_PAYMENT_REQUEST: "GET_INTENTION_PAYMENT_REQUEST",
  GET_INTENTION_PAYMENT_SUCCESS: "GET_INTENTION_PAYMENT_SUCCESS",
};

const initialState = {
  loading: false,
  publishableKey: null,
  clientSecret: null,
  
};

export default function stripeReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CONFIG_STRIPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_CONFIG_STRIPE_SUCCESS:
      return {
        ...state,
        publishableKey: action.payload.publishableKey,
        loading: false,
      };
    case types.GET_INTENTION_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_INTENTION_PAYMENT_SUCCESS:
      return {
        ...state,
        clientSecret: action.payload.clientSecret,
        loading: false,
      };

    default:
      return state;
  }
}

export function stripeActionCreators() {
  return {
    getConfigRequest: () => ({
      type: types.GET_CONFIG_STRIPE_REQUEST,
    }),

    getConfigSuccess: (payload) => ({
      type: types.GET_CONFIG_STRIPE_SUCCESS,
      payload
    }),

    getIntentionPaymentRequest: () => ({
      type: types.GET_INTENTION_PAYMENT_REQUEST,
    }),

    getIntentionPaymentSuccess: (payload) => ({
      type: types.GET_INTENTION_PAYMENT_SUCCESS,
      payload
    }),
  };
}
