export const loginTypes = {
  REQUEST: "LOGIN_REQUEST",
  SUCCESS: "LOGIN_SUCCESS",
  ERROR: "LOGIN_ERROR",
};

const initialState = {
  loading: false,
  error: "",
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case loginTypes.REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case loginTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        token: action.payload,
      };
    case loginTypes.ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export const loginActionCreators = () => ({
  loginRequest: () => ({
    type: loginTypes.REQUEST,
  }),
  loginSuccess: (payload) => ({
    type: loginTypes.SUCCESS,
    payload,
  }),
  loginError: () => ({
    type: loginTypes.ERROR,
  }),
});
