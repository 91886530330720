import { fork, all } from "redux-saga/effects";

import loginSaga from "./login-sagas";
import filesSaga from "./files-sagas";
import pessoaSaga from "./pessoa-sagas";
import templateSaga from "./template-sagas";
import cepSaga from "./cep-sagas";
import imageSaga from "./image-sagas";
import stripeSaga from "./stripe.saga";

import meuPainelSagaFlow from "./sagasFlow/meu-painel-sagasFlow";

export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(pessoaSaga),
    fork(filesSaga),
    fork(templateSaga),
    fork(cepSaga),
    fork(imageSaga),
    fork(meuPainelSagaFlow),
    fork(stripeSaga),
  ]);
}
